<template>
  <div>
    <Executar
        :rotina="currentRotina"
        :close="closeExecutar"
        :executarRotina="executarRotina"
    ></Executar>
    <Loading :isActive="loading"></Loading>
    <div class="filters row" style="margin-top:0">
      <div class="col-1-1">
        <span class="filters__search">
            <input
                type="text"
                v-model="termo"
                placeholder="Buscar por..."
                v-on:input="debounceLoadRotinas()">
            <img
                style="position: relative; right: -10px;"
                src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwNDE2ZiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
            >
        </span>

        <div class="pull-right">
          <!--              <button v-on:click="openCreate" class="button button-success button-block">-->
          <!--                <fa-icon icon="plus"/>-->
          <!--                Nova Rotina-->
          <!--              </button>-->
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <datatable :columns="table.columns" :data="items" class="table">
        <template slot-scope="{ row }">
          <tr>
            <td align="center" class="highlight">{{ row.id }}</td>
            <td align="center">{{ row.titulo }}</td>
            <td align="center">
              <span class="label success" v-if="+row.ativo === 1">Ativo</span>
              <span class="label error" v-else>Inativo</span>
            </td>
            <td align="center">
              {{ row.tipo_execucao | humanTipoExecucao }}
              <small v-if="row.tipo_execucao === 'A'">{{ row.cron | humanCron(crons) }}</small>
            </td>
            <td align="center">{{ row.datahora_atualizacao }}</td>
            <td class="actions">
              <button type="button" class="button button-success" v-on:click="openExecutar(row)" title="Executar">
                <fa-icon icon="play"/>
              </button>
            </td>
          </tr>
        </template>
      </datatable>
    </div>
    <div style="display: flex; justify-content: center;">
      <pagination
          :data="table.paginator"
          :limit="10"
          @pagination-change-page="loadRotinas"
      ></pagination>
    </div>
  </div>
</template>
<script>
import clone from 'just-clone';
import {get, post, put, del} from '@/helpers/apiRequest';
import Executar from './Executar';
import _debounce from 'lodash/debounce';

export default {
  name: 'rotina',
  components: {
    Executar,
  },
  data() {
    return {
      loading: false,
      currentRotina: {},
      items: [],
      descPlural: 'Rotinas',
      descSingular: 'Rotina',
      crons: {
        '* * * * *': 'A cada minuto',
        '*/2 * * * *': 'A cada 2 minutos',
        '*/3 * * * *': 'A cada 3 minutos',
        '*/4 * * * *': 'A cada 4 minutos',
        '*/5 * * * *': 'A cada 5 minutos',
        '*/6 * * * *': 'A cada 6 minutos',
        '*/7 * * * *': 'A cada 7 minutos',
        '*/8 * * * *': 'A cada 8 minutos',
        '*/9 * * * *': 'A cada 9 minutos',
        '*/10 * * * *': 'A cada 10 minutos',
        '*/15 * * * *': 'A cada 15 minutos',
        '*/20 * * * *': 'A cada 20 minutos',
        '*/30 * * * *': 'A cada 30 minutos',
        '0 */1 * * *': 'A cada 1 hora',
        '0 */2 * * *': 'A cada 2 horas',
        '0 */3 * * *': 'A cada 3 horas',
        '0 */4 * * *': 'A cada 4 horas',
        '0 */6 * * *': 'A cada 6 horas',
        '0 */12 * * *': 'A cada 12 horas',
        '0 0 * * *': 'Pontualmente à 0h',
        '0 1 * * *': 'Pontualmente à 1h',
        '0 2 * * *': 'Pontualmente às 2h',
        '0 3 * * *': 'Pontualmente às 3h',
        '0 4 * * *': 'Pontualmente às 4h',
        '0 5 * * *': 'Pontualmente às 5h',
        '0 6 * * *': 'Pontualmente às 6h',
        '0 7 * * *': 'Pontualmente às 7h',
        '0 8 * * *': 'Pontualmente às 8h',
        '0 9 * * *': 'Pontualmente às 9h',
        '0 10 * * *': 'Pontualmente às 10h',
        '0 11 * * *': 'Pontualmente às 11h',
        '0 12 * * *': 'Pontualmente às 12h',
        '0 13 * * *': 'Pontualmente às 13h',
        '0 14 * * *': 'Pontualmente às 14h',
        '0 15 * * *': 'Pontualmente às 15h',
        '0 16 * * *': 'Pontualmente às 16h',
        '0 17 * * *': 'Pontualmente às 17h',
        '0 18 * * *': 'Pontualmente às 18h',
        '0 19 * * *': 'Pontualmente às 19h',
        '0 20 * * *': 'Pontualmente às 20h',
        '0 21 * * *': 'Pontualmente às 21h',
        '0 22 * * *': 'Pontualmente às 22h',
        '0 23 * * *': 'Pontualmente às 23h',
      },
      termo: '',
      currentPage: 1,
      table: {
        filter: '',
        columns: [
          {label: 'ID', field: 'id', align: 'center', sortable: false},
          {label: 'Título', field: 'titulo', align: 'center', sortable: false},
          {label: 'Ativo', field: 'ativo', align: 'center', sortable: false},
          {label: 'Modo', field: 'tipo_execucao', align: 'center', sortable: false},
          {label: 'Última alteração', field: 'datahora_atualizacao', align: 'center', sortable: false},
          {label: '', field: '', sortable: false},
        ],
        paginator: {},
      },
    };
  },
  computed: {},
  filters: {
    humanCron(cron, crons) {
      return crons[cron] || cron;
    },
    humanTipoExecucao(tipo_execucao) {
      switch (tipo_execucao) {
        case 'M':
          return 'Manual';
        case 'A':
          return 'Autmático';
      }
      return tipo_execucao;
    },
  },
  methods: {
    debounceLoadRotinas: _debounce(function () {
      this.loadRotinas(1);
    }, 300),
    loadRotinas(page = 1) {
      this.currentPage = page;

      get(`/admin/rotinas?page=${page}&q=${this.termo}`)
          .then((json) => {
            this.items = json.itens;
            this.table.paginator = json.paginator;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    removeRotina(rotina) {
      this.$swal({
        title: 'Confirma Remover?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
          .then((res) => {
            if (res.value) {
              this.loading = true;
              del(`/admin/rotinas/${rotina.id}`)
                  .then((json) => {
                    this.loading = false;
                    this.$swal({
                      title: 'Sucesso!',
                      text: json.message,
                      icon: 'success',
                      confirmButtonText: 'Fechar',
                    })
                        .then(() => {
                          this.loadRotinas();
                        });
                  })
                  .catch((err) => {
                    this.loading = false;
                    this.$swal({
                      title: 'Falha ao remover',
                      text: err.message,
                      icon: 'error',
                    });
                  });
            }
          })
          .catch(() => {
          });
    },
    openEdit(rotina) {
      this.currentRotina = clone(rotina);
      this.$modal.show('rotinaEdit');
    },
    closeEdit() {
      this.$modal.hide('rotinaEdit');
    },
    openCreate() {
      this.currentRotina = clone({});
      this.$modal.show('rotinaEdit');
    },
    openExecutar(rotina) {
      if (rotina.parametros.length) {
        this.currentRotina = rotina;
        this.$modal.show('rotinaExecutar');
      } else {
        this.$swal({
          title: 'Confirma Executar?',
          text: 'Ao confirmar, esta ação não poderá ser desfeita',
          showCancelButton: true,
          icon: 'warning',
          confirmButtonText: 'Executar',
          cancelButtonText: 'Cancelar',
        })
            .then((res) => {
              if (res.value) {
                this.loading = true;
                this.executarRotina(rotina);
              }
            })
            .catch(() => {
            });
      }
    },
    closeExecutar() {
      this.currentRotina = {};
      this.$modal.hide('rotinaExecutar');
    },
    executarRotina(rotina, hasFile = false, files = []) {
      if (hasFile) {
        let formData = new FormData();

        files.forEach((file) => {
          formData.append(file.name, file.file);
        });

        post(`/admin/rotinas/${rotina.id}/executar`, formData, {isFile: true})
            .then((json) => {
              this.loading = false;
              this.$swal({
                title: 'Sucesso!',
                text: json.message,
                icon: 'success',
                confirmButtonText: 'Fechar',
              })
                  .then(() => {
                    this.closeExecutar();
                  });
            })
            .catch((err) => {
              this.loading = false;
              this.$swal({
                title: 'Falha ao executar',
                text: err.message,
                icon: 'error',
              });
            });
      } else {
        post(`/admin/rotinas/${rotina.id}/executar`)
            .then((json) => {
              this.loading = false;
              this.$swal({
                title: 'Sucesso!',
                text: json.message,
                icon: 'success',
                confirmButtonText: 'Fechar',
              })
                  .then(() => {
                    this.closeExecutar();
                  });
            })
            .catch((err) => {
              this.loading = false;
              this.$swal({
                title: 'Falha ao executar',
                text: err.message,
                icon: 'error',
              });
            });
      }
    },
    createRotina() {
    },
    updateRotina() {
    },
  },
  beforeMount() {
    this.loadRotinas();
  },
};
</script>
