<template>
  <modal name="tarefaInfo" height="auto" width="600" :scrollable="true" :clickToClose="true">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab :title="descricao">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="">
                    Última Sincronização
                    {{ tarefa ? tarefa.tarefa : '' }}
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table" style="margin-left: auto; margin-right: auto;">
                    <thead>
                    <tr>
                      <th>Chave</th>
                      <th>Valor</th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(valor, chave) in tarefa.ultimaSincronizacao">
                        <td>{{ chave }}</td>
                        <td style="word-wrap: break-word;">{{ valor }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">
      <button class="button" v-on:click="close">Fechar</button>
      <button class="button button-success" v-on:click="syncTarefa(tarefa)">Sincronizar</button>
      <button class="button button-primary" v-on:click="loadTarefas">Atualizar</button>
    </div>
  </modal>
</template>
<script>
import CloseModal from "../../components/CloseModal";

export default {
  name: "tarefaInfo",
  props: ["close", "tarefa", "descricao", "loadTarefas", "syncTarefa"],
  components: { CloseModal },
};
</script>
